import { motion } from "framer-motion";
import { ForwardedRef, forwardRef } from "react";
import { TSize } from "../../breakpoints";
import SafetyMan1SVG from "../../images/safety/safety_man_1_L.svg";
import SafetyMan1SVGS from "../../images/safety/safety_man_1_S.svg";
import SafetyMan1SVGM from "../../images/safety/safety_man_1_M.svg";
import SafetyMan2SVG from "../../images/safety/safety_man_2_L.svg";
import SafetyMan2SVGS from "../../images/safety/safety_man_2_S.svg";
import SafetyMan2SVGM from "../../images/safety/safety_man_2_M.svg";
import SafetyMan3SVG from "../../images/safety/safety_man_3_L.svg";
import SafetyMan3SVGS from "../../images/safety/safety_man_3_S.svg";
import SafetyMan3SVGM from "../../images/safety/safety_man_3_M.svg";
import CustomizationMan1SVGS from "../../images/customization/Customization_Man_1_S.svg";
import CustomizationMan1SVGM from "../../images/customization/Customization_Man_1_M.svg";
import CustomizationMan1SVGL from "../../images/customization/Customization_Man_1_L.svg";
import CustomizationMan1SVGXL from "../../images/customization/Customization_Man_1_XL.svg";
import CustomizationMan2SVGS from "../../images/customization/Customization_Man_2_S.svg";
import CustomizationMan2SVGM from "../../images/customization/Customization_Man_2_M.svg";
import CustomizationMan2SVGL from "../../images/customization/Customization_Man_2_L.svg";
import CustomizationMan2SVGXL from "../../images/customization/Customization_Man_2_XL.svg";
import CustomizationMan3SVGS from "../../images/customization/Customization_Man_3_S.svg";
import CustomizationMan3SVGM from "../../images/customization/Customization_Man_3_M.svg";
import CustomizationMan3SVGL from "../../images/customization/Customization_Man_3_L.svg";
import CustomizationMan3SVGXL from "../../images/customization/Customization_Man_3_XL.svg";
import { SlideSvgBackground } from "./styles";
interface ISvg {
  width?: number | string;
  height?: number | string;
  size?: TSize;
  className?: string;
}
export const Line = ({ width = 2, height = 794 }: ISvg): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 2 794"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1L0.999982 404.089L0.999965 794"
      stroke="#D0D4DC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeDasharray="16 16"
    />
  </svg>
);

const SafetyManBase1 = (
  { className, size = TSize.S }: ISvg,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element => {
  const defineSize = (): JSX.Element | undefined => {
    if (size <= TSize.M) {
      if (size === TSize.S) {
        return <SafetyMan1SVGS />;
      }
      if (size === TSize.M) {
        return <SafetyMan1SVGM />;
      }
    } else {
      const { width, height } = ((): { width: number; height: number } => {
        if (size === TSize.L) {
          return { width: 817, height: 859 };
        } else {
          return { width: 940, height: 988 };
        }
      })();
      return <SafetyMan1SVG width={width} height={height} />;
    }
  };
  return (
    <SlideSvgBackground ref={ref} className={className}>
      {defineSize()}
    </SlideSvgBackground>
  );
};
export const SafetyMan1 = motion(forwardRef(SafetyManBase1));

const SafetyManBase2 = (
  { className, size = TSize.S }: ISvg,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element => {
  const defineSize = (): JSX.Element | undefined => {
    if (size <= TSize.M) {
      if (size === TSize.S) {
        return <SafetyMan2SVGS />;
      }
      if (size === TSize.M) {
        return <SafetyMan2SVGM />;
      }
    } else {
      const { width, height } = ((): { width: number; height: number } => {
        if (size === TSize.L) {
          return { width: 817, height: 859 };
        } else {
          return { width: 940, height: 988 };
        }
      })();
      return <SafetyMan2SVG width={width} height={height} />;
    }
  };
  return (
    <SlideSvgBackground ref={ref} className={className}>
      {defineSize()}
    </SlideSvgBackground>
  );
};
export const SafetyMan2 = motion(forwardRef(SafetyManBase2));

const SafetyManBase3 = (
  { className, size = TSize.S }: ISvg,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element => {
  const defineSize = (): JSX.Element | undefined => {
    if (size <= TSize.M) {
      if (size === TSize.S) {
        return <SafetyMan3SVGS />;
      }
      if (size === TSize.M) {
        return <SafetyMan3SVGM />;
      }
    } else {
      const { width, height } = ((): { width: number; height: number } => {
        if (size === TSize.L) {
          return { width: 817, height: 859 };
        } else {
          return { width: 940, height: 988 };
        }
      })();
      return <SafetyMan3SVG width={width} height={height} />;
    }
  };
  return (
    <SlideSvgBackground ref={ref} className={className}>
      {defineSize()}
    </SlideSvgBackground>
  );
};
export const SafetyMan3 = motion(forwardRef(SafetyManBase3));

const CustomizationManBase1 = (
  { className, size = TSize.S }: ISvg,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element => {
  const defineSize = (): JSX.Element | undefined => {
    if (size === TSize.S) {
      return <CustomizationMan1SVGS />;
    }
    if (size === TSize.M) {
      return <CustomizationMan1SVGM />;
    }
    if (size === TSize.L) {
      return <CustomizationMan1SVGL />;
    }
    if (size === TSize.XL) {
      return <CustomizationMan1SVGXL />;
    }
    return <CustomizationMan1SVGS />;
  };
  return (
    <SlideSvgBackground ref={ref} className={className}>
      {defineSize()}
    </SlideSvgBackground>
  );
};
export const CustomizationMan1 = motion(forwardRef(CustomizationManBase1));

const CustomizationManBase2 = (
  { className, size = TSize.S }: ISvg,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element => {
  const defineSize = (): JSX.Element | undefined => {
    if (size === TSize.S) {
      return <CustomizationMan2SVGS />;
    }
    if (size === TSize.M) {
      return <CustomizationMan2SVGM />;
    }
    if (size === TSize.L) {
      return <CustomizationMan2SVGL />;
    }
    if (size === TSize.XL) {
      return <CustomizationMan2SVGXL />;
    }
    return <CustomizationMan2SVGS />;
  };
  return (
    <SlideSvgBackground ref={ref} className={className}>
      {defineSize()}
    </SlideSvgBackground>
  );
};
export const CustomizationMan2 = motion(forwardRef(CustomizationManBase2));

const CustomizationManBase3 = (
  { className, size = TSize.S }: ISvg,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element => {
  const defineSize = (): JSX.Element | undefined => {
    if (size === TSize.S) {
      return <CustomizationMan3SVGS />;
    }
    if (size === TSize.M) {
      return <CustomizationMan3SVGM />;
    }
    if (size === TSize.L) {
      return <CustomizationMan3SVGL />;
    }
    if (size === TSize.XL) {
      return <CustomizationMan3SVGXL />;
    }
    return <CustomizationMan3SVGS />;
  };
  return (
    <SlideSvgBackground ref={ref} className={className}>
      {defineSize()}
    </SlideSvgBackground>
  );
};
export const CustomizationMan3 = motion(forwardRef(CustomizationManBase3));
