import { ForwardedRef, forwardRef, useContext, useEffect } from "react";
import {
  MotionValue,
  motion,
  useAnimationControls,
  useTransform
} from "framer-motion";
import { PageHeader, SizeContext } from "../../components";
import { PageBackground, LineHolder } from "./styles";
import { WorkingType } from "./WorkingType";

const LineAnimationUp = {
  hidden: {
    y: -200,
    opacity: 0
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.5, delayChildren: 5 }
  }
};
const LineAnimationDown = {
  hidden: {
    y: 200,
    opacity: 0
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.5, delayChildren: 5 }
  }
};

const AboutSectionBase = (
  {
    scrollYProgress
  }: {
    scrollYProgress: MotionValue<number>;
  },
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element => {
  const isShowLines = useTransform(scrollYProgress, (pos) => pos >= 0.1);
  const lineHolderControls = useAnimationControls();
  useEffect(() => {
    const unSubscribe = isShowLines.on("change", (latest) => {
      if (latest) {
        lineHolderControls.start({ opacity: 1 });
        lineHolderControls.start("visible");
      }
    });
    return () => {
      unSubscribe();
    };
  }, [scrollYProgress]);
  const size = useContext(SizeContext);
  return (
    <div ref={ref}>
      <PageBackground>
        <motion.div className="scroll_helper">
          <PageHeader
            title="Что такое Атом.Проект?"
            description="Это помощник для команд с разной методологией"
            size={size}
          />
          <LineHolder
            animate={lineHolderControls}
            initial={{ opacity: 0 }}
            variants={LineAnimationUp}
            size={size}>
            <WorkingType
              variants={LineAnimationUp}
              initial="hidden"
              animate={lineHolderControls}
              type="agile"
              size={size}
              scroll={scrollYProgress}
            />
            <WorkingType
              variants={LineAnimationUp}
              type="scrum"
              animate={lineHolderControls}
              initial="hidden"
              size={size}
              scroll={scrollYProgress}
            />
            <WorkingType
              variants={LineAnimationDown}
              type="kanban"
              animate={lineHolderControls}
              initial="hidden"
              size={size}
              scroll={scrollYProgress}
            />
            <WorkingType
              variants={LineAnimationDown}
              type="waterfall"
              animate={lineHolderControls}
              initial="hidden"
              size={size}
              scroll={scrollYProgress}
            />
            <WorkingType
              variants={LineAnimationDown}
              animate={lineHolderControls}
              scroll={scrollYProgress}
              size={size}
              initial="hidden"
            />
          </LineHolder>
        </motion.div>
        <div id="about" />
      </PageBackground>
    </div>
  );
};

export const AboutSection = forwardRef(AboutSectionBase);
