import { H, LIGHT_COLORS, P2 } from "@greenatom/components";
import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import { motion, useAnimationControls } from "framer-motion";
import { THFontType } from "@greenatom/components/types/typography/H/types";
import { TP2FontType } from "@greenatom/components/types/typography/P2/types";
import { Line } from "../../../images/svg";
import { TSize } from "../../../breakpoints";
import { Wrapper } from "./styles";
import { IContent, IWorkingTypeProps, TWorkingType } from "./types";

const defineText = (type?: TWorkingType): IContent => {
  switch (type) {
    case "agile":
      return {
        title: "AGILE",
        description: "Планируйте спринты и визуализируйте нагрузку.",
        color: LIGHT_COLORS.muspelheim7,
        textColor: LIGHT_COLORS.muspelheim9,
        top: 0,
        bottom: 0,
        showPoint: 0.2
      };
    case "scrum":
      return {
        title: "SCRUM",
        description: "Обсуждайте задачи внутри команды и с заказчиками.",
        color: LIGHT_COLORS.vanaheim7,
        textColor: LIGHT_COLORS.vanaheim9,
        top: "50px",
        showPoint: 0.4
      };
    case "kanban":
      return {
        title: "KANBAN",
        description: "Следите за статусом задач.",
        color: LIGHT_COLORS.freyja7,
        textColor: LIGHT_COLORS.freyja9,
        bottom: "30%",
        showPoint: 0.5
      };
    case "waterfall":
      return {
        title: "WATERFALL",
        description: "Все требования известны вначале и не меняются",
        color: LIGHT_COLORS.jotunheim7,
        textColor: LIGHT_COLORS.jotunheim9,
        top: "20%",
        showPoint: 0.7
      };
    default:
      return {
        title: "И НЕ ТОЛЬКО...",
        description:
          "В «Атом.Проекте» есть инструменты для комфортной работы разных команд.",
        color: LIGHT_COLORS.midgard7,
        textColor: LIGHT_COLORS.midgard9,
        bottom: "25%",
        showPoint: 0.85
      };
  }
};

const bubbleVariants = {
  visible: {
    opacity: 1,
    transition: { duration: 0.2 }
  },
  hidden: {
    opacity: 0,
    transition: { duration: 0.2 }
  }
};

const WorkingTypeBase = (
  { type, scroll, size }: IWorkingTypeProps,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element => {
  const content: IContent = defineText(type);
  const bubbleControl = useAnimationControls();
  const defineTitleType = (): THFontType => {
    switch (size) {
      case TSize.S:
        return "cancer";
      case TSize.M:
        return "gemini";
      case TSize.L:
        return "taurus";
      case TSize.XL:
        return "aries";
    }
  };
  const defineDescriptionType = (): TP2FontType => {
    switch (size) {
      case TSize.S:
        return "corvus";
      default:
        return "pisces";
    }
  };
  const [isVisible, setIsVisibe] = useState(false);

  useEffect(() => {
    if (isVisible) {
      bubbleControl.start("visible");
    } else {
      bubbleControl.start("hidden");
    }
  }, [isVisible]);
  useEffect(() => {
    const unSubscribe = scroll.on("change", (latest) => {
      setIsVisibe(latest >= content.showPoint);
    });

    return () => {
      unSubscribe();
    };
  }, [scroll]);

  return (
    <Wrapper contentProps={content} ref={ref} size={size}>
      <motion.div
        className="working_type_wrapper"
        variants={bubbleVariants}
        initial="hidden"
        animate={bubbleControl}>
        <div className="circle">
          <H type={defineTitleType()} className="working_type_title">
            {content.title}
          </H>
        </div>
        <P2 type={defineDescriptionType()} className="working_type_description">
          {content.description}
        </P2>
      </motion.div>
      <Line height={"100%"} />
    </Wrapper>
  );
};
export const WorkingType = motion(forwardRef(WorkingTypeBase));
