import { MotionValue, useAnimationControls } from "framer-motion";
import {
  ForwardedRef,
  forwardRef,
  useContext,
  useEffect,
  useState
} from "react";
import { SlideText } from "../../components/SlideText";
import { SafetyMan1, SafetyMan2, SafetyMan3 } from "../../images/svg";
import { SizeContext } from "../../components";
import { PageBackground } from "./styles";
import {
  firstSlideVariants,
  secondSlideVariants,
  thirdSlideVariants
} from "./variants";

const SafetySectionBase = (
  {
    scrollYProgress
  }: {
    scrollYProgress: MotionValue<number>;
  },
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element => {
  const [activeSlide, setActiveSlide] = useState(0);
  const animControl = useAnimationControls();
  const size = useContext(SizeContext);
  useEffect(() => {
    animControl.start("exitImageSlide1");
    animControl.start("exitTextSlide1");
    animControl.start("exitImageSlide2");
    animControl.start("exitTextSlide2");
    animControl.start("exitImageSlide3");
    animControl.start("exitTextSlide3");
    if (activeSlide === 1) {
      animControl.start("visibleSlide1");
    }
    if (activeSlide === 2) {
      animControl.start("visibleSlide2");
    }
    if (activeSlide === 3) {
      animControl.start("visibleSlide3");
    }
  }, [activeSlide]);

  useEffect(() => {
    const unSubscribe = scrollYProgress.on("change", (latest) => {
      if (latest >= 0 && latest < 0.4) {
        setActiveSlide(1);
      } else if (latest >= 0.4 && latest < 0.7) {
        setActiveSlide(2);
      } else if (latest >= 0.7) {
        setActiveSlide(3);
      }
    });

    return () => {
      unSubscribe();
    };
  }, [scrollYProgress]);

  return (
    <PageBackground ref={ref} id="safety" size={size}>
      <div className="scroll_helper">
        <div className="left slide">
          <SafetyMan1
            className="man"
            size={size}
            initial={"hiddenImage"}
            animate={animControl}
            variants={firstSlideVariants}
          />
          <SlideText
            initial={"hiddenText"}
            animate={animControl}
            variants={firstSlideVariants}
            size={size}
            header="01"
            title="Локальное хранилище"
            subTitle="Локальная установка в вашей собственной инфраструктуре"
            description="Имейте полный контроль и 100% владение данными. Знайте, где
            хранятся ваши данные и что с ними происходит, без внешнего
            воздействия."
            className="text_holder"
          />
        </div>
        <div className="right slide">
          <SafetyMan2
            className="man"
            size={size}
            initial={"hiddenImage"}
            animate={animControl}
            variants={secondSlideVariants}
          />
          <SlideText
            initial={"hiddenText"}
            animate={animControl}
            variants={secondSlideVariants}
            size={size}
            header="02"
            title="Шифрование"
            subTitle="Высочайший уровень безопасности и защиты с различными функциями"
            description="Https-шифрование ваших данных, безопасность паролем,
            двухфакторная аутентификация, определение времени выполнения
            сеанса и многое другое."
            className="text_holder"
          />
        </div>
        <div className="left slide">
          <SafetyMan3
            className="man"
            size={size}
            initial={"hiddenImage"}
            animate={animControl}
            variants={thirdSlideVariants}
          />
          <SlideText
            initial={"hiddenText"}
            animate={animControl}
            variants={thirdSlideVariants}
            size={size}
            header="03"
            title="Импортозамещение"
            subTitle="Произведено в России"
            description="Мы обеспечим вам стабильную работу над проектами. Не
            беспокойтесь о внезапном прекращении поддержки сервиса и утечке
            важных данных."
            className="text_holder"
          />
        </div>
      </div>
    </PageBackground>
  );
};

export const SafetySection = forwardRef(SafetySectionBase);
