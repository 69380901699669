import { H } from "@greenatom/components";
import { THFontType } from "@greenatom/components/types/typography/H/types";
import { TSize } from "../../breakpoints";
import { HeaderBackground } from "./styles";

export const PageHeader = ({
  title,
  description,
  size
}: {
  title: string;
  description: string;
  size: TSize;
}): JSX.Element => {
  const defineFontType = (): { title: THFontType; description: THFontType } => {
    if (size <= TSize.M) {
      return { title: "virgo", description: "capricornus" };
    } else {
      return { title: "cancer", description: "libra" };
    }
  };
  return (
    <HeaderBackground size={size}>
      <H type={defineFontType().title}>{title}</H>
      <H type={defineFontType().description}>{description}</H>
    </HeaderBackground>
  );
};
