import { H, P2 } from "@greenatom/components";
import { Background, BottomHolder } from "./styles";

const Year = new Date();
export const Footer = (): JSX.Element => (
  <Background>
    <BottomHolder>
      <H type="libra">Атом.Проект</H>
      <P2 type="corvus">
        ©️ {Year.getFullYear()} Гринатом. Все права сайта защищены.
      </P2>
    </BottomHolder>
  </Background>
);
