import { LIGHT_COLORS } from "@greenatom/components";
import styled, { css } from "styled-components";
import { TSize } from "../../breakpoints";

export const FormBackground = styled.div<{ size: TSize }>`
  display: flex;
  background-color: ${LIGHT_COLORS.grayscale0};
  ${({ size }) => {
    if (size <= TSize.M) {
      return css`
        padding: 16px 24px;
      `;
    } else {
      return css`
        padding: 40px;
      `;
    }
  }}

  gap: 24px;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 16px;
  border: 2px solid ${LIGHT_COLORS.grayscale2};
`;
