import styled, { css } from "styled-components";
import { TSize } from "../../breakpoints";

export const PageBackground = styled.div<{ size: TSize }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 350vh;
  scroll-snap-align: start end;
  ${({ size }) => {
    switch (size) {
      case TSize.S:
        return css`
          .scroll_helper {
            height: 684px;
          }
          .left {
            .text_holder {
              width: 462px;
              right: 24px;
            }
          }
          .right {
            .text_holder {
              width: 462px;
              left: 30px;
            }
          }
          .man {
            width: 524px;
            height: 661px;
          }
        `;
      case TSize.M:
        return css`
          .scroll_helper {
            height: 636px;
          }
          .left {
            .text_holder {
              width: 491px;
              right: 129px;
            }
          }
          .right {
            .text_holder {
              width: 491px;
              left: 129px;
            }
          }
          .man {
            width: 627px;
            height: 660px;
          }
        `;
      case TSize.L:
        return css`
          .scroll_helper {
            height: 932px;
          }

          .left {
            .text_holder {
              width: 535px;
              right: 40px;
            }
          }
          .right {
            .text_holder {
              width: 535px;
              left: 40px;
            }
          }
          .man {
            width: 817px;
            height: 859px;
          }
        `;
      case TSize.XL:
        return css`
          .scroll_helper {
            height: 988px;
          }

          .left {
            .text_holder {
              right: 200px;
              width: 574px;
            }
          }
          .right {
            .text_holder {
              left: 200px;
              width: 574px;
            }
          }
          .man {
            width: 939px;
            height: 988px;
          }
        `;
    }
  }}
  .scroll_helper {
    position: sticky;
    overflow: hidden;
    top: 0;
    .slide {
      height: 100%;
      width: 100%;
    }
    .left {
      position: absolute;
      .text_holder {
        position: absolute;
        height: 100%;
        top: 0;
        bottom: 0;
      }
      .man {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    .right {
      position: absolute;
      .text_holder {
        position: absolute;
        height: 100%;
        top: 0;
        bottom: 0;
      }
      .man {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
`;
