import styled, { css } from "styled-components";
import { TSize } from "../../breakpoints";
import mainComputer from "../../images/main/main_computer.png";

export const TextHolder = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  height: 70%;
  font-weight: 900px;
  gap: 24px;
  left: 40px;
  bottom: 0;
  top: 0;
  right: 0;
  margin: auto 0;
`;

export const MainPageBackground = styled.div<{ size: TSize }>`
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-snap-align: start;
  width: 100%;
  height: 100vh;

  .main_background {
    position: absolute;
    width: 120%;
    bottom: 0;
  }
  .main_man {
    position: absolute;
    z-index: 5;
    width: 307.802px;
    height: 494.692px;
    bottom: 90px;
    right: -10px;
  }
  .main_computer {
    background-image: url(${mainComputer});
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    z-index: 4;
    width: 583.594px;
    height: 501.432px;
    right: -70px;
    bottom: 90px;
    .main_computer_screen {
      position: absolute;
      width: 60%;
      border-radius: 4px;
      left: 23px;
      top: 29px;
    }
  }
  ${TextHolder} {
    width: 40%;
    gap: 40px;
  }
  ${({ size }) => {
    if (size <= TSize.S) {
      return css`
        .main_man {
          width: 307px;
          height: 494px;
          bottom: 80px;
          right: -20px;
        }
        .main_computer {
          width: 583px;
          height: 501px;
          right: -80px;
          bottom: 80px;
          .main_computer_screen {
            left: 23px;
            top: 29px;
          }
        }
      `;
    } else if (size <= TSize.M) {
      return css`
        .main_man {
          width: 340px;
          height: 547px;
          bottom: 80px;
          right: -10px;
        }
        .main_computer {
          width: 664px;
          height: 570px;
          right: -50px;
          bottom: 80px;
          .main_computer_screen {
            left: 26px;
            top: 33px;
          }
        }
      `;
    } else if (size <= TSize.L) {
      return css`
        .main_man {
          width: 446px;
          height: 716.8px;
          bottom: 80px;
          right: -60px;
        }
        .main_computer {
          width: 897.785px;
          height: 771.389px;
          right: -200px;
          bottom: 80px;
          .main_computer_screen {
            left: 35px;
            top: 45px;
          }
        }
      `;
    } else {
      return css`
        .main_man {
          width: 602.33px;
          height: 968.05px;
          bottom: 0px;
          right: -40px;
        }
        .main_computer {
          width: 1028.001px;
          height: 883.272px;
          right: -50px;
          .main_computer_screen {
            top: 50px;
            left: 40px;
          }
        }
      `;
    }
  }}
`;
