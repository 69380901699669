import { Button, Divider, H, P1, Tabs } from "@greenatom/components";
import { handleNavigate } from "../../helpers";
import { Background, Holder } from "./styles";

export const Header = ({
  currentTarget
}: {
  currentTarget: string | undefined;
}): JSX.Element => (
  <Background>
    <Holder>
      <H type="libra" onClick={() => handleNavigate("#main")}>
        Атом.Проект
      </H>
      <Divider direction="column" />
      <Tabs value={currentTarget}>
        <Tabs.Tab
          label="О продукте"
          value="#about"
          onClick={() => handleNavigate("#about")}
        />
        <Tabs.Tab
          label="Безопасность"
          value="#safety"
          onClick={() => handleNavigate("#safety")}
        />
        <Tabs.Tab
          label="Инструменты"
          value="#tools"
          onClick={() => handleNavigate("#tools")}
        />
        <Tabs.Tab
          label="Кастомизация"
          value="#customization"
          onClick={() => handleNavigate("#customization")}
        />
      </Tabs>
    </Holder>
    <Button onClick={() => handleNavigate("#form")}>
      <P1 type="phoenix">Начать работу</P1>
    </Button>
  </Background>
);
