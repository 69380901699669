import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { TSize } from "../../breakpoints";

export const PageBackground = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 500vh;
  scroll-snap-align: start;
  .scroll_helper {
    position: sticky;
    display: flex;
    flex-direction: column;
    top: 0;
  }
  #about {
    position: absolute;
    bottom: 20%;
    opacity: 0;
    pointer-events: none;
    left: 0;
  }
`;
export const LineHolder = styled(motion.div)<{ size: TSize }>`
  display: flex;
  ${({ size }) => {
    switch (size) {
      case TSize.S:
        return css`
          gap: 168px;
          height: 540px;
          padding: 0px 54px;
        `;
      case TSize.M:
        return css`
          gap: 211px;
          height: 492px;
          padding: 0px 64px;
        `;
      case TSize.L:
        return css`
          gap: 211px;
          height: 724px;
          padding: 0px 78px;
        `;
      case TSize.XL:
        return css`
          gap: 310px;
          height: 780px;
          padding: 0px 100px;
        `;
    }
  }}
  box-sizing: border-box;
  width: 100%;
`;
