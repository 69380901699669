import styled, { css } from "styled-components";
import { TSize } from "../../../breakpoints";

export const Circle = styled.div<{ backgroundColor: string; size: TSize }>`
  position: absolute;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${({ size }) => {
    if (size <= TSize.M) {
      return css`
        width: 82px;
        height: 82px;
        top: 10px;
      `;
    } else {
      return css`
        width: 116px;
        height: 116px;
        top: -20px;
      `;
    }
  }}
`;
export const ToolBlockBackground = styled.div<{
  direction?: string;
  size: TSize;
}>`
  width: 100%;
  padding: 40px;
  height: 658px;
  ${({ size }) => {
    switch (size) {
      case TSize.S:
        return css`
          img {
            width: 646px;
            height: 317px;
          }
        `;
      case TSize.M:
        return css`
          img {
            width: 624px;
            height: 307px;
          }
        `;
      case TSize.L:
        return css`
          img {
            width: 580px;
            height: 284px;
          }
        `;
      case TSize.XL:
        return css`
          img {
            width: 820px;
            height: 40px;
          }
        `;
    }
  }}
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: ${({ direction }) => (direction === "left" ? "start" : "end")};
  .text-holder {
    display: flex;
    width: 50%;
    text-align: ${({ direction }) => direction};
    flex-direction: column;
    z-index: 5;
    .title {
      margin-bottom: 24px;
    }
  }
  ${Circle} {
    ${({ direction }) =>
      direction === "left"
        ? css`
            left: 0;
          `
        : css`
            right: 0;
          `};
  }
  img {
    margin-top: 40px;
    box-sizing: border-box;
    height: 401.581px;
    border-radius: 16px;
  }
`;
