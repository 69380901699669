import { motion } from "framer-motion";
import styled from "styled-components";

export const SlideTextHolder = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  .description {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }
`;
