import { H, P2 } from "@greenatom/components";
import { motion } from "framer-motion";
import { ForwardedRef, forwardRef } from "react";
import { THFontType } from "@greenatom/components/types/typography/H/types";
import { TSize } from "../../breakpoints";
import { SlideTextHolder } from "./styles";

interface ISlideTextProps {
  header: string;
  title: string;
  subTitle: string;
  description: string;
  className?: string;
  size: TSize;
}

const SlideTextBase = (
  { header, title, subTitle, description, className, size }: ISlideTextProps,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element => {
  const defineType = (): { header: THFontType; title: THFontType } => {
    if (size === TSize.S) {
      return { header: "taurus", title: "cancer" };
    } else {
      return { header: "aries", title: "gemini" };
    }
  };
  return (
    <SlideTextHolder className={className} ref={ref}>
      <H type={defineType().header}>{header}</H>
      <H type={defineType().title}>{title}</H>
      <div className="description">
        <H type="capricornus">{subTitle}</H>
        <P2 type="pisces">{description}</P2>
      </div>
    </SlideTextHolder>
  );
};

export const SlideText = motion(forwardRef(SlideTextBase));
