import { Button, Input, InputPhone, TextArea } from "@greenatom/components";
import { useState } from "react";
import { TSize } from "../../breakpoints";
import { FormBackground } from "./styles";
import { IFormProps, IFormValues } from "./types";
//import { IFormProps } from "./types";

export const Form = ({
  className,
  size,
  onSubmit
}: IFormProps): JSX.Element => {
  const [formValues, setFormValues] = useState<IFormValues>({
    name: "",
    company: "",
    industry: "",
    email: "",
    phone: "",
    description: ""
  });
  const [formErrors, setFormErrors] = useState<IFormValues>({
    name: "",
    company: "",
    industry: "",
    email: "",
    phone: ""
  });
  const regexValidEmail =
    // eslint-disable-next-line no-useless-escape, optimize-regex/optimize-regex
    /[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/gi;
  const regexValidPhone =
    /^(\s*)?(\+)?([ ()+:=_-]?\d[ ()+:=_-]?){10,14}(\s*)?$/gi;

  const handleInputChange = (key: string, value: string): void => {
    setFormValues({ ...formValues, [key]: value });
    setFormErrors({ ...formErrors, [key]: "" });
  };

  const handleClearForm = (): void => {
    setFormValues({
      name: "",
      company: "",
      industry: "",
      email: "",
      phone: ""
    });
  };

  const validateForm = (): void => {
    let valid = true;
    Object.keys(formValues).map((key) => {
      if (
        formValues[key as keyof IFormValues]?.length === 0 &&
        key !== "description"
      ) {
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          [key]: "Необходимое поле"
        }));
        valid = false;
      } else {
        if (key === "email") {
          if (!regexValidEmail.test(formValues.email)) {
            setFormErrors((prevFormErrors) => ({
              ...prevFormErrors,
              email: "Неверный адрес электронный почты"
            }));
            valid = false;
          }
        }
        if (key === "phone") {
          if (!regexValidPhone.test(formValues.phone)) {
            setFormErrors((prevFormErrors) => ({
              ...prevFormErrors,
              phone: "Неверный телефон"
            }));
            valid = false;
          }
        }
      }
    });
    if (valid) {
      onSubmit(formValues, handleClearForm);
    }
  };
  const inputSize = size <= TSize.M ? "m" : "l";
  return (
    <FormBackground className={className} size={size} id="form">
      <Input
        placeholder={"Как к вам обращаться?"}
        isRequired
        type="text"
        size={inputSize}
        onChange={(e, value) => handleInputChange("name", value)}
        value={formValues.name}
        status={formErrors.name ? "error" : undefined}
        statusText={formErrors.name}
      />
      <Input
        placeholder={"Компания"}
        size={inputSize}
        isRequired
        onChange={(e, value) => handleInputChange("company", value)}
        value={formValues.company}
        status={formErrors.company ? "error" : undefined}
        statusText={formErrors.company}
      />
      <Input
        placeholder={"Отрасль"}
        size={inputSize}
        isRequired
        type="text"
        onChange={(e, value) => handleInputChange("industry", value)}
        value={formValues.industry}
        status={formErrors.industry ? "error" : undefined}
        statusText={formErrors.industry}
      />
      <Input
        placeholder={"Электронная почта"}
        size={inputSize}
        isRequired
        onChange={(e, value) => handleInputChange("email", value)}
        value={formValues.email}
        status={formErrors.email ? "error" : undefined}
        statusText={formErrors.email}
      />
      <InputPhone
        placeholder={"Телефон для связи"}
        size={inputSize}
        isRequired
        onChange={(e, value) => handleInputChange("phone", value)}
        value={formValues.phone}
        status={formErrors.phone ? "error" : undefined}
        statusText={formErrors.phone}
      />
      <TextArea
        isAutoSize
        placeholder="Расскажите подробнее о вашей задаче и текущих системах?"
        size={inputSize}
        onChange={(e, value) => handleInputChange("description", value)}
        value={formValues.description}
      />
      <Button onClick={() => validateForm()}>Оставить заявку</Button>
    </FormBackground>
  );
};
