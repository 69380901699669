import styled from "styled-components";
import { motion, useInView, useScroll } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Header, Footer } from "../components";
import { MainSection } from "./Main";
import { AboutSection } from "./About";
import { SafetySection } from "./Safety";
import { CustomizationSection } from "./Customization";
import { ToolsSection } from "./Tools";
import { FormSection } from "./Form";

export const Page = (): JSX.Element => {
  const ScrollContainer = useRef<HTMLDivElement>(null);
  const aboutScrollTarget = useRef<HTMLDivElement>(null);
  const safetyScrollTarget = useRef<HTMLDivElement>(null);
  const customizationScrollTarget = useRef<HTMLDivElement>(null);
  const toolsScrollTarget = useRef<HTMLDivElement>(null);
  const [currentTarget, setCurrentTarget] = useState<string | undefined>();

  const aboutSectionScroll = useScroll({
    container: ScrollContainer,
    target: aboutScrollTarget
  }).scrollYProgress;
  const safetySectionScroll = useScroll({
    container: ScrollContainer,
    target: safetyScrollTarget,
    offset: ["start end", "end end"]
  }).scrollYProgress;
  const customizaitionSectionScroll = useScroll({
    container: ScrollContainer,
    target: customizationScrollTarget,
    offset: ["start end", "end end"]
  }).scrollYProgress;

  const isAboutInView = useInView(aboutScrollTarget, {
    root: ScrollContainer
  });
  const isSafetyInView = useInView(safetyScrollTarget, {
    root: ScrollContainer
  });
  const isToolsInView = useInView(toolsScrollTarget, {
    root: ScrollContainer
  });
  const isCustomizationInView = useInView(customizationScrollTarget, {
    root: ScrollContainer
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (isCustomizationInView) {
      navigate("#customization");
      setCurrentTarget("#customization");
    } else if (isToolsInView) {
      navigate("#tools");
      setCurrentTarget("#tools");
    } else if (isSafetyInView) {
      navigate("#safety");
      setCurrentTarget("#safety");
    } else if (isAboutInView) {
      navigate("#about");
      setCurrentTarget("#about");
    } else {
      navigate("");
      setCurrentTarget(undefined);
    }
  }, [isAboutInView, isSafetyInView, isToolsInView, isCustomizationInView]);

  return (
    <Wrapper>
      <Layout>
        <Header currentTarget={currentTarget} />
        <PageContent ref={ScrollContainer}>
          <MainSection />
          <AboutSection
            scrollYProgress={aboutSectionScroll}
            ref={aboutScrollTarget}
          />
          <SafetySection
            scrollYProgress={safetySectionScroll}
            ref={safetyScrollTarget}
          />
          <ToolsSection ref={toolsScrollTarget} />
          <CustomizationSection
            scrollYProgress={customizaitionSectionScroll}
            ref={customizationScrollTarget}
          />
          <FormSection />
          <Footer />
        </PageContent>
      </Layout>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Layout = styled.div`
  overflow: clip;
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;
`;
const PageContent = styled(motion.div)`
  min-width: 1024px;
  min-height: 768px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y proximity;
`;
