import { H, useSnackBar } from "@greenatom/components";
import { useContext, useState } from "react";
import { Form, SizeContext } from "../../components";
import { IFormValues } from "../../components/Form/types";
import { SectionBackground } from "./styles";

export const FormSection = (): JSX.Element => {
  const [openSnackBar, closeSnackBar] = useSnackBar();
  const [keySnackBar, setKeySnackBar] = useState<number | string>("");

  const handleOpenSnackBar = (
    message: string,
    duration?: number,
    action?: { text: string; onClick: () => void }
  ): void => {
    const key = openSnackBar({
      message,
      duration,
      action
    });
    setKeySnackBar(key);
  };
  const handleCloseSnackBar = (): void => {
    closeSnackBar(keySnackBar);
  };
  const handleSubmit = (
    formValues: IFormValues,
    cleanForm: () => void
  ): void => {
    fetch("/mail", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formValues)
    })
      .then((response) => {
        if (response.status === 200) {
          handleOpenSnackBar("Заявка отправлена, мы с вами свяжемся", 5000, {
            text: "Здорово",
            onClick: () => handleCloseSnackBar
          });
          cleanForm();
        } else {
          handleOpenSnackBar(
            "Произошла ошибка! Заяка не была отправлена. Попробуйте позже!",
            5000
          );
        }
      })
      .catch(() => {
        handleOpenSnackBar(
          "Произошла ошибка! Заяка не была отправлена. Попробуйте позже!",
          5000
        );
      });
    //todo
  };
  const size = useContext(SizeContext);
  return (
    <SectionBackground size={size}>
      <H type="cancer">Заполните форму и мы выйдем на связь</H>
      <Form className="form-background" onSubmit={handleSubmit} size={size} />
    </SectionBackground>
  );
};
