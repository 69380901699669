import { LIGHT_COLORS } from "@greenatom/components";
import { ForwardedRef, forwardRef, useContext } from "react";
import { PageHeader } from "../../components/PageHeader";
import taskList from "../../images/tools/TaskList.png";
import commandDesk from "../../images/tools/CommandDesk.png";
import changeLog from "../../images/tools/ChangeLog.png";
import notifications from "../../images/tools/Notifications.png";
import files from "../../images/tools/Files.png";
import ganttChart from "../../images/tools/GanttChart.png";
import calendar from "../../images/tools/Calendar.png";
import reports from "../../images/tools/Reports.png";
import budgeting from "../../images/tools/Budgeting.png";
import communication from "../../images/tools/Communication.png";
import { SizeContext } from "../../components";
import { BlocksHolder, SectionBackground } from "./styles";
import { ToolBlock } from "./ToolBlock";

const ToolsSectionBase = (
  // eslint-disable-next-line no-empty-pattern
  {},
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element => {
  const size = useContext(SizeContext);

  return (
    <SectionBackground id="tools" ref={ref}>
      <PageHeader
        title="Инструменты планирования"
        description="Удобный набор инструментов, который позволит настроить процесс работы под вашу стратегию."
        size={size}
      />
      <BlocksHolder>
        <ToolBlock
          size={size}
          title="Список задач"
          description={[
            "Создайте динамический список задач, чтобы составить план работ.",
            " Настройте персональные фильтры и группировки для каждой команды. "
          ]}
          circleColor={LIGHT_COLORS.jotunheim7}
          img={taskList}
        />
        <ToolBlock
          size={size}
          title="Командные доски"
          description={[
            "Доска - это комфортный способ работы команды.",
            " Создавайте версионные, проектные, Kanban и другие доски."
          ]}
          circleColor={LIGHT_COLORS.vanaheim7}
          direction="right"
          img={commandDesk}
        />
        <ToolBlock
          size={size}
          title="Журнал изменений"
          description={[
            "Будьте в курсе всех обновлений проекта.",
            "Используйте журнал изменений, чтобы информировать коллег о последних событиях.",
            "Публикуйте заметки о событиях в новостном модуле."
          ]}
          circleColor={LIGHT_COLORS.midgard7}
          img={changeLog}
        />
        <ToolBlock
          size={size}
          title="Уведомления"
          direction="right"
          description={[
            "Получайте уведомления о всех действиях, на которые вы подписались. ",
            "Сообщения будут приходить на электронную почту. "
          ]}
          circleColor={LIGHT_COLORS.uthgard7}
          img={notifications}
        />
        <ToolBlock
          size={size}
          title="Работа с файлами"
          description={[
            "Прикрепляйте файлы к задачам, работайте с ними в многопользовательском режиме с сохранением всех версий."
          ]}
          circleColor={LIGHT_COLORS.muspelheim7}
          img={files}
        />
        <ToolBlock
          size={size}
          title="Диаграмма Ганта"
          direction="right"
          description={[
            "Следите за прогрессом вместе с командой.",
            "Создайте план реализации проекта.",
            "Отметьте ключевые вехи."
          ]}
          circleColor={LIGHT_COLORS.alvheim7}
          img={ganttChart}
        />
        <ToolBlock
          size={size}
          title="Календарь"
          description={[
            "Создайте календарный план работ.",
            "Настройте количество рабочих и выходных дней под себя.",
            "Вы сможете просматривать задачи на неделю, месяц или год.",
            "Заведите персональные календари для разных команд и проектов."
          ]}
          circleColor={LIGHT_COLORS.heimdallr7}
          img={calendar}
        />
        <ToolBlock
          size={size}
          title="Отчеты"
          direction="right"
          description={[
            "Система готовит отчеты о временных и",
            "финансовых затратах по проектам и пакетам работ.",
            "Минимальный отчетный период – день, максимальный вы выбираете сами. "
          ]}
          circleColor={LIGHT_COLORS.freyja7}
          img={reports}
        />
        <ToolBlock
          size={size}
          title="Бюджетирование"
          description={[
            "Планируйте ресурсы, следите за их расходом.",
            "Назначьте ставку участнику команды для конвертации часовых затрат в реальные суммы."
          ]}
          circleColor={LIGHT_COLORS.uthgard7}
          img={budgeting}
        />
        <ToolBlock
          size={size}
          title="Общение"
          direction="right"
          description={[
            "Комментируйте задачи и файлы.",
            "Общайтесь с коллегами и всегда оставайтесь в курсе всех изменений."
          ]}
          circleColor={LIGHT_COLORS.muspelheim7}
          img={communication}
        />
      </BlocksHolder>
    </SectionBackground>
  );
};
export const ToolsSection = forwardRef(ToolsSectionBase);
