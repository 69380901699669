export const firstSlideVariants = {
  hiddenImage: {
    x: -600
  },
  hiddenText: {
    x: 600
  },
  visibleSlide1: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      mass: 0.3,
      bounce: 0.5
    }
  },
  exitImageSlide1: {
    opacity: 0,
    x: -600,
    transition: {
      type: "ease-in-out",
      duration: 0.1
    }
  },
  exitTextSlide1: {
    opacity: 0,
    x: -600,
    transition: {
      type: "ease-in-out",
      duration: 0.1
    }
  }
};

export const secondSlideVariants = {
  hiddenImage: {
    x: 600,
    opacity: 0
  },
  hiddenText: {
    x: -600,
    opacity: 0
  },
  visibleSlide2: {
    x: 0,
    transition: {
      type: "ease-in-out",
      duration: 0.2
    },
    opacity: 1
  },
  exitImageSlide2: {
    opacity: 0,
    x: 700,
    transition: {
      type: "ease-in-out",
      duration: 0.1
    }
  },
  exitTextSlide2: {
    opacity: 0,
    x: 700,
    transition: {
      type: "ease-in-out",
      duration: 0.1
    }
  }
};

export const thirdSlideVariants = {
  hiddenImage: {
    x: -600,
    opacity: 0
  },
  hiddenText: {
    x: 600,
    opacity: 0
  },
  visibleSlide3: {
    x: 0,
    transition: {
      type: "ease-in-out",
      duration: 0.2
    },
    opacity: 1
  },
  exitImageSlide3: {
    opacity: 0,
    x: -900,
    transition: {
      type: "ease-in-out",
      duration: 0.1
    }
  },
  exitTextSlide3: {
    opacity: 0,
    x: -900,
    transition: {
      type: "ease-in-out",
      duration: 0.1
    }
  }
};
