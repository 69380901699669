export const sizes = {
  S: 1124,
  M: 1340,
  L: 1740,
  XL: 1920
};

export enum TSize {
  S = 0,
  M = 1,
  L = 2,
  XL = 3
}
