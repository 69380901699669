import styled, { css } from "styled-components";
import { LIGHT_COLORS } from "@greenatom/components";
import { TSize } from "../../../breakpoints";
import { IContent } from "./types";

interface IWrapper {
  contentProps: IContent;
  size: TSize;
}

export const Wrapper = styled.div<IWrapper>`
  position: relative;
  width: max-content;
  height: 100%;
  ${({ size }) => {
    switch (size) {
      case TSize.S:
        return css`
          .circle {
            width: 59px;
            height: 59px;
          }
          .working_type_title {
            top: 10px;
            left: 16px;
          }
          .working_type_description {
            max-width: 255px;
          }
        `;
      case TSize.M:
        return css`
          .circle {
            width: 81px;
            height: 81px;
          }
          .working_type_title {
            top: 16px;
            left: 20px;
          }
          .working_type_description {
            max-width: 348px;
          }
        `;
      case TSize.L:
        return css`
          .circle {
            width: 77px;
            height: 77px;
          }
          .working_type_title {
            top: 14px;
            left: 16px;
          }
          .working_type_description {
            max-width: 255px;
          }
        `;
      case TSize.XL:
        return css`
          .circle {
            width: 116px;
            height: 116px;
          }
          .working_type_title {
            top: 26px;
            left: 28px;
          }
          .working_type_description {
            max-width: 559px;
          }
        `;
    }
  }}
  .working_type_wrapper {
    box-sizing: border-box;
    width: max-content;
    height: max-content;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    z-index: 5;
    ${({ contentProps }) =>
      contentProps.top !== undefined &&
      css`
        top: ${contentProps.top};
      `}
    ${({ contentProps }) =>
      contentProps.bottom !== undefined &&
      css`
        bottom: ${contentProps.bottom};
      `}
    transform: translateX(-50%);
    margin: auto 0;
  }
  .circle {
    position: relative;
    border-radius: 50%;

    color: ${({ contentProps }) => contentProps?.textColor};
    background-color: ${({ contentProps }) => contentProps?.color};
    .working_type_title {
      position: absolute;
      width: max-content;
    }
  }
  .working_type_description {
    margin-top: 16px;
    position: absolute;
    width: max-content;
    word-wrap: normal;
    color: ${LIGHT_COLORS.grayscale10};
  }
`;
