import { SnackBarProvider } from "@greenatom/components";
import { BrowserRouter } from "react-router-dom";
import { Page } from "./pages";
import "@greenatom/components/styles/index.css";
import { SizeProvider } from "./components";
export const App = (): JSX.Element => (
  <BrowserRouter>
    <SnackBarProvider>
      <SizeProvider>
        <Page />
      </SizeProvider>
    </SnackBarProvider>
  </BrowserRouter>
);
