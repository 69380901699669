import styled from "styled-components";

export const SectionBackground = styled.div`
  scroll-snap-align: start;
`;
export const BlocksHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 86px;
  box-sizing: border-box;
  align-items: end;
  padding: 40px;
`;
