import styled from "styled-components";

export const Background = styled.div`
  background-color: #fefefe;
  height: 92px;
  display: flex;
  width: 100%;
  padding: 18px 40px 18px 40px;
  box-sizing: border-box;
  justify-content: space-between;
  //position: fixed;
  z-index: 10;
`;

export const Holder = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
