import styled, { css } from "styled-components";
import { TSize } from "../../breakpoints";

export const HeaderBackground = styled.div<{ size: TSize }>`
  display: flex;
  flex-direction: column;
  ${({ size }) => {
    if (size === TSize.XL || size === TSize.L) {
      return css`
        padding: 64px 58px 40px 40px;
      `;
    } else {
      return css`
        padding: 32px 24px;
      `;
    }
  }}
  gap: 24px;
`;
