import { H, P2 } from "@greenatom/components";
import { TSize } from "../../../breakpoints";
import { Circle, ToolBlockBackground } from "./styles";

type TDirection = "left" | "right";

interface IToolBlock {
  title: string;
  description: string[];
  direction?: TDirection;
  img?: string;
  circleColor: string;
  size: TSize;
}

export const ToolBlock = ({
  title,
  description,
  direction = "left",
  img,
  circleColor,
  size
}: IToolBlock): JSX.Element => (
  <ToolBlockBackground direction={direction} size={size}>
    <Circle backgroundColor={circleColor} size={size} />
    <div className="text-holder">
      <H type="cancer" className="title">
        {title}
      </H>
      {description.map((text) => (
        <P2 type="pisces" key={text}>
          {text}
        </P2>
      ))}
    </div>
    <img src={img} />
  </ToolBlockBackground>
);
