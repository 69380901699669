import { createContext, useEffect, useState } from "react";
import { TSize, sizes } from "../../breakpoints";

const defineSize = (): TSize => {
  if (window.innerWidth <= sizes.S) {
    return TSize.S;
  }
  if (window.innerWidth <= sizes.M) {
    return TSize.M;
  }
  if (window.innerWidth <= sizes.L) {
    return TSize.L;
  }
  if (window.innerWidth <= sizes.XL) {
    return TSize.XL;
  }
  return TSize.XL;
};

export const SizeContext = createContext<TSize>(defineSize());
export const SizeProvider = ({
  children
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [pageSize, setPageSize] = useState<TSize>(defineSize());
  const fitSize = (): void => {
    setPageSize(defineSize());
  };

  useEffect(() => {
    window.addEventListener("resize", fitSize);
    return () => {
      window.removeEventListener("resize", fitSize);
    };
  }, []);
  return (
    <SizeContext.Provider value={pageSize}>{children}</SizeContext.Provider>
  );
};
