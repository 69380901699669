import { LIGHT_COLORS } from "@greenatom/components";
import styled from "styled-components";

export const Background = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  scroll-snap-align: center;
  justify-content: space-between;
  //position: fixed;
  z-index: 10;
`;

export const BottomHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${LIGHT_COLORS.grayscale4};
  color: ${LIGHT_COLORS.grayscale12};
  width: 100%;
  padding: 24px 40px;
  box-sizing: border-box;
`;
