import styled, { css } from "styled-components";
import { TSize } from "../../breakpoints";
import dots from "../../images/form/dots.png";

export const SectionBackground = styled.div<{ size: TSize }>`
  width: 100%;
  min-height: 100%;
  display: flex;
  scroll-snap-align: start;
  align-items: center;
  flex-direction: column;
  ${({ size }) => {
    if (size <= TSize.M) {
      return css`
        justify-content: start;
        gap: 16px;
        .form-background {
          width: 709px;
        }
      `;
    } else {
      return css`
        justify-content: center;
        gap: 40px;
        .form-background {
          width: 900px;
        }
      `;
    }
  }}
  padding: 24px;
  background-image: url(${dots});
`;
