import { Button, H, P1 } from "@greenatom/components";
import { IconBrokenArrowDown1 } from "@greenatom/icons";
import { THFontType } from "@greenatom/components/types/typography/H/types";
import { useContext } from "react";
import { TSize } from "../../breakpoints";
import mainMan from "../../images/main/main_man.png";
import mainComputerScreen from "../../images/main/main_computer_screen.gif";
import mainBackground from "../../images/main/main_background.png";
import { SizeContext } from "../../components";
import { handleNavigate } from "../../helpers";
import { MainPageBackground, TextHolder } from "./styles";

export const MainSection = (): JSX.Element => {
  const size = useContext(SizeContext);
  const defineType = (): THFontType => {
    if (size <= TSize.M) {
      return "gemini";
    }
    if (size <= TSize.L) {
      return "taurus";
    }
    if (size <= TSize.XL) {
      return "aries";
    }
    return "aries";
  };
  return (
    <MainPageBackground id="main" size={size}>
      <img className="main_background" src={mainBackground} />
      <img className="main_man" src={mainMan} />
      <div className="main_computer">
        <img className="main_computer_screen" src={mainComputerScreen} />
      </div>
      <TextHolder>
        <P1 type="aquilla">Командный онлайн планировщик</P1>
        <H type={defineType()}>
          Эффективное управление проектами в безопасной среде
        </H>
        <Button viewType="secondary" onClick={() => handleNavigate("#about")}>
          <IconBrokenArrowDown1 /> <P1 type="phoenix">Узнать больше</P1>
        </Button>
      </TextHolder>
    </MainPageBackground>
  );
};
